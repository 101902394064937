<template>
  <v-main>
    <sidebar />
    <v-container class="row__carousel">
      <v-row>
        <v-card
          class="mx-auto backgroundImg"
          :style="{
            backgroundImage:
              'url(https://yourcardonline.co.za/files/img/background.png)',
          }"
        >
          <div style="position:relative;">
            <img
              class="logo"
              :src="
                `https://yourcardonline.co.za/files/Businesses/${id}/img/identity/logo.png`
              "
            />
            <v-carousel
              v-model="model"
              cycle
              height="25vh"
              :hide-delimiter-background="true"
              :hide-delimiters="true"
              interval="6000"
              :show-arrows="false"
            >
              <v-carousel-item v-for="(img, i) in imgs" :key="i">
                <div
                  :style="{ backgroundImage: 'url(' + img + ')' }"
                  class="slide__background"
                ></div>
              </v-carousel-item>
            </v-carousel>
            <div class="top-btn-wrapper">
              <v-btn
                :color="color"
                elevation="2"
                tile
                @click="helpDialog = true"
              >
                <v-icon color="white">mdi-help-circle</v-icon>
              </v-btn>
              <div class="three-btn__wrapper">
                <div class="three-btn__wrapper-inner">
                  <v-btn
                    :color="color"
                    elevation="2"
                    tile
                    :href="
                      `https://api.whatsapp.com/send?phone=${data.whatsapp}`
                    "
                  >
                    <v-icon color="white">mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn :color="color" elevation="2" tile @click="share">
                    <v-icon color="white">
                      mdi-share-variant
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!safari"
                    class="disabled"
                    :color="color"
                    elevation="2"
                    tile
                    :disabled="!deferredPrompt"
                    @click="install"
                  >
                    <v-icon color="white">
                      mdi-download
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="safari"
                    class="disabled"
                    :color="color"
                    elevation="2"
                    tile
                    @click="iosDialog = true"
                  >
                    <v-icon color="white">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <v-card-text>
            <!-- INTERACTION -->
            <v-row>
              <div class="top-btn__row">
                <v-btn color="white" :href="`tel:${data.mobile}`">
                  <v-icon :color="color">mdi-phone</v-icon>
                </v-btn>
                <v-btn color="white" :href="`mailto:${data.email}`">
                  <v-icon :color="color">mdi-email-outline</v-icon>
                </v-btn>
                <v-btn color="white" :href="data.locations.g_map">
                  <v-icon :color="color">mdi-google-maps</v-icon>
                </v-btn>
                <v-btn color="white" :href="data.website">
                  <v-icon :color="color">mdi-web</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-row>
              <div class="top-btn__row">
                <v-btn
                  color="white"
                  elevation="2"
                  @click="addressDialog = true"
                >
                  <!-- <v-icon class="mr-3">mdi-google-maps</v-icon> -->
                  Address
                </v-btn>
                <v-btn color="white" elevation="2" @click="zohoDialog = true">
                  <!-- <v-icon class="mr-3">mdi-chat-processing-outline</v-icon> -->
                  Leave a Message
                </v-btn>
              </div>
            </v-row>
            <!-- LINKEDIN -->
            <v-row class="mt-4 mb-1 ml-n4 mr-n4">
              <v-banner
                class="w-100 linkedin__banner"
                elevation="2"
                :color="color"
                dark
                :class="data.profile.linkedin == '' ? 'linked-empty' : ''"
              >
                <a
                  v-if="data.profile.linkedin"
                  :href="data.profile.linkedin"
                  style="text-decoration:none;"
                >
                  <span style="position: relative; top: 2px;color:#fff;"
                    >Connect Here </span
                  ><v-icon style="color:#fff;">mdi-linkedin</v-icon>
                </a>
              </v-banner>
            </v-row>
            <!-- COMPANY ABOUT -->
            <v-container>
              <v-row>
                <v-col class="p-0">
                  <v-btn
                    color="white"
                    elevation="2"
                    block
                    @click="aboutDialog = true"
                    >About</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="pro">
                <v-col class="p-0">
                  <v-btn
                    color="white"
                    elevation="2"
                    block
                    @click="servicesDialog = true"
                    >Services</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="brochure && pro">
                <v-col class="p-0">
                  <v-btn
                    color="white"
                    elevation="2"
                    block
                    :href="
                      `https://yourcardonline.co.za/files/Businesses/${id}/documents/brochure.pdf`
                    "
                    download
                    >Brochures</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="data.formLink">
                <v-col class="p-0">
                   <v-btn
                    color="white"
                    elevation="2"
                    block
                    :href="`${data.formLink}`">
                    Online Forms
                    </v-btn>
                </v-col>
              </v-row>
              <!-- <v-row v-if="data.id == 1">
                <v-col class="p-0">
                   <v-btn
                    color="white"
                    elevation="2"
                    block
                    :href="`https://sandbox.advisorassist.co.za/contact-us/`">
                    Support Ticket
                    </v-btn>
                </v-col>
              </v-row> -->
              <v-row v-if="data.clientPortalLoginUrl">
                <v-col class="p-0">
                   <v-btn
                    color="white"
                    elevation="2"
                    block
                    :href="`${data.clientPortalLoginUrl}`">
                    Client Portal Login
                    </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <!-- QR CODE -->
            <v-container>
              <v-card class="mx-auto" width="200px">
                <v-card-text>
                  <img width="100%" :src="data.qr" />
                </v-card-text>
              </v-card>
            </v-container>
            <!-- CONTACT/SHARE -->
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    dark
                    block
                    :color="color"
                    :href="
                      `https://yourcardonline.co.za/files/Businesses/${id}/vcards/contact.vcf`
                    "
                    @click="install"
                    >Add to Contacts</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn dark block :color="color" @click="share"
                    >Share Business Card</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <img
                    class="mx-auto pulse"
                    @click="toggleNav"
                    :src="
                      `https://yourcardonline.co.za/files/img/yourcard-btn.png`
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <!-- Address Modal -->
    <v-dialog v-model="addressDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Physical Address
        </v-card-title>
        <v-card-text>
          <p v-if="data.locations.branch_type">
            {{ data.locations.branch_type }}
          </p>
          <p v-if="data.locations.street1">{{ data.locations.street1 }}</p>
          <p v-if="data.locations.street2">{{ data.locations.street2 }}</p>
          <p v-if="data.locations.street3">{{ data.locations.street3 }}</p>
          <p v-if="data.locations.suburb">{{ data.locations.suburb }}</p>
          <p v-if="data.locations.city">{{ data.locations.city }}</p>
          <p v-if="data.locations.province">{{ data.locations.province }}</p>
          <p v-if="data.locations.country">{{ data.locations.country }}</p>
          <p v-if="data.locations.post_code">{{ data.locations.post_code }}</p>
          <v-btn color="red darken-1" text @click="addressDialog = false">
            Close
          </v-btn>
          <v-btn color="darken-1 float-right" text :href="data.locations.g_map">
            Navigate
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Help Modal -->
    <v-dialog v-model="helpDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">
          YOURCard Benefits
        </v-card-title>
        <v-card-text>
          <ul>
            <li>
              <strong>Instant Contact</strong> for customers to call, text or
              email you.
            </li>
            <li><strong>One Tap</strong> navigation to your address.</li>
            <li>
              <strong>Quick Access</strong> to your services or product
              brochures.
            </li>
            <li>
              <strong>Contact Forms</strong> for customers to submit requests.
            </li>
            <li>
              <strong>Save YOURCard</strong> for customers to save your
              information to their phone.
            </li>
            <li>
              <strong>QR Code</strong> enabling customers to share your
              information with leads.
            </li>
            <li>
              <strong>CRM Integration</strong> for automated customer request
              management.
            </li>
          </ul>
          <v-btn color="red darken-1" text @click="helpDialog = false">
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Zoho Modal -->
    <v-dialog v-model="zohoDialog">
      <v-card>
        <v-card-title class="headline">
          Leave a message
        </v-card-title>
        <v-card-text>
          <zoho
            :color="color"
            :type="data.ZohoContactFormType"
            :guid="data.ZohoFormGUID"
            :singleLine="data.YC_Business_Code"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- About Us Modal -->
    <v-dialog v-model="aboutDialog">
      <v-card>
        <v-card-title class="headline">
          About
        </v-card-title>
        <v-card-text>
          <div v-html="data.profile.about"></div>
          <v-btn :color="color + ' darken-1'" text @click="aboutDialog = false">
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Our Services Modal -->
    <v-dialog v-model="servicesDialog">
      <v-card>
        <v-card-title>
          Services
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, i) in services" :key="i">
              <v-expansion-panel-header>
                {{ item.header }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.body }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn
            class="mt-3"
            color="red darken-1"
            text
            @click="servicesDialog = false"
          >
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
     <!-- IOS Modal -->
    <v-dialog v-model="iosDialog">
      <v-card>
        <v-card-title>
          Save as Bookmark
        </v-card-title>
        <v-card-text>
         <h4>Save in Safari</h4>
         <p>To learn how to save a bookmark in Safari on your iPhone/iPad click <a href="https://support.apple.com/en-za/guide/iphone/iph42ab2f3a7/ios" target="_blank"><strong>HERE</strong></a></p>
          <h4>Save in Chrome</h4>
         <p>To learn how to save a bookmark in Chrome on your iPhone/iPad click <a href="https://browserhow.com/how-to-bookmark-and-manage-bookmarks-in-chrome-ios/" target="_blank"><strong>HERE</strong></a></p>
          <v-btn
            class="mt-3"
            color="red darken-1"
            text
            @click="iosDialog = false"
          >
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { replace } from "lodash";
import Sidebar from "@/views/Sidebar";
import Zoho from "@/views/Zoho";
export default {
  name: "Businesses",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "",
    },
    color_dark: {
      type: String,
      default: "",
    },
    color_bd: {
      type: String,
      default: "",
    },
    banner_count: {
      type: Number,
      default: 0,
    },
    brochure: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: {},
    },
    services: {
      type: Array,
      default: [],
    },
    pro: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Sidebar,
    Zoho,
  },
  data: () => ({
    model: 0,
    imgs: [],
    helpDialog: false,
    addressDialog: false,
    zohoDialog: false,
    aboutDialog: false,
    servicesDialog: false,
    profileDialog: false,
    iosDialog: false,
    deferredPrompt: null,
    safari: false,
  }),
  created() {
    console.log(this.data);
    // check if browser is iPhone of iPad
    var userAgent = window.navigator.userAgent;

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      this.safari = true;
    }

    if (this.banner_count && this.banner_count > 0) {
      for (let i = 1; i <= this.banner_count; i++) {
        this.imgs.push(
          `https://yourcardonline.co.za/files/Businesses/${this.id}/img/banner/${i}.png`
        );
      }
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    share() {
      if (navigator.share) {
        navigator
          .share({
            title: `${this.data.name}  Digital Business Card`,
            text: `${this.data.name} Digital Business Card`,
            url: window.location.href,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    toggleNav() {
      this.$store.dispatch("app/navOpen", {
        navOpen: !this.$store.state.app.navOpen,
      });
    },
  },
  computed: {
    preparedMobile() {
      const pattern = new RegExp(/ /, "g");
      return replace(this.data.mobile, pattern, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  background-color: rgba(255, 255, 255, 0.7);
  height: 12vh;
  position: absolute;
  top: 20px;
  width: auto;
  z-index: 1;
}

.three-btn__wrapper {
  display: flex;
  justify-content: flex-end;
  button.v-btn,
  a.v-btn {
    min-width: auto !important;
    padding: 5px !important;
  }
}

.top-btn-wrapper button.v-btn,
a.v-btn,
.profile-block__btn {
  min-width: auto !important;
  padding: 5px !important;
}

.top-btn-wrapper {
  position: absolute;
  right: 0;
  bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.three-btn__wrapper .three-btn__wrapper-inner {
  width: 140px;
  //  width: 94px;
  justify-content: space-between;
  display: flex;
}
.v-btn__content {
  text-transform: initial;
}
.row__carousel {
  max-width: 768px;
  margin: 0 auto;
}
.backgroundImg {
  background-size: cover;
  background-repeat: no-repeat;
}
.slide__background {
  background-position: center right;
  height: 25vh;
  width: 100vw;
  max-width: 768px;
  background-size: cover;
}
.top-btn__row {
  justify-content: space-between;
  width: calc(100% - 50px) !important;
  display: flex;
  margin: 12px auto;
  @media (max-width: 370px) {
    width: calc(100% - 20px) !important;
  }
}

.disabled.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg,
.disabled.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.pulse {
  animation: pulse 4s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.profile-block {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__name {
    font-size: 20px;
  }
  &__title {
    margin-bottom: 0;
  }
  &__divider {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 15px;
  }
}
.v-avatar {
  height: 60px !important;
  width: 60px !important;
}
</style>
